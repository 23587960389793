import {useEffect, useState} from "react";

const backgroundBlack = {
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "4",
};

const buttonStyle = {
    width: "100%",
    height: "40px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#fff",
    color: "#333",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
const buttonColorStyle = {
    width: "100%",
    height: "40px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#ff8515",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const closePopup = () => {
    document.querySelector(".alert_popup_wrapper").style.display = "none";
};

const requestPermission = () => {
    navigator.mediaDevices
        .getUserMedia({video: false, audio: true})
        .then(() => {
            closePopup();
            alert("마이크 권한을 수락하였습니다.");
        })
        .catch(() => {
            closePopup();
            alert("마이크권한요청을 거절하였습니다. 안드로이드 기기일경우 설정 > 어플리케이션 > 새김스튜던트 에서 마이크권한을 활성화해주세요.");
        });
};

const AlertPopup = (props) => {
    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    zIndex: "9999999999999",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "none",
                }}
                className="alert_popup_wrapper"
            >
                <div style={backgroundBlack} className="background_black"></div>
                <div
                    style={{
                        display: "flex",
                        backgroundColor: "#ffffff",
                        width: "340px",
                        height: "auto",
                        padding: "1rem",
                        position: "relative",
                        zIndex: 5,
                        borderRadius: "12px",
                        flexDirection: "column",
                    }}
                    className="alert_popup_box"
                >
                    <h1
                        style={{
                            color: "#333",
                            fontSize: "20px",
                            marginBottom: "1rem",
                        }}
                        className="alert_popup_title"
                    >
                        {props.data.title}
                    </h1>
                    <span
                        style={{
                            color: "#333",
                            fontSize: "14px",
                            marginBottom: "1.6rem",
                            wordBreak: "keep-all",
                            lineHeight: "21px",
                        }}
                        className="alert_popup_content"
                    >
                        {props.data.content}
                    </span>
                    <ul className="alert_popup_button_wrapper">
                        <button onClick={closePopup} style={buttonColorStyle} className="alert_popup_button c_0">
                            {props.data.button_text[0]}
                        </button>
                        {String(props.data.button_text[1]).length > 0 ? (
                            <button onClick={closePopup} style={buttonStyle} className="alert_popup_button">
                                {props.data.button_text[1]}
                            </button>
                        ) : (
                            ""
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};
export default AlertPopup;
