import { getAsyncActionTypes } from "src/stores/utils";
import apis from "src/apis";

const prefix = "ranking";

export const [GetRealtimeRankingActions] = [
  "GET_REALTIME_RANKING"
].map(actionName => getAsyncActionTypes(prefix, actionName));

const initialState = {
  realtimeRanking: [],
  rankObj: [],
  startRank: 0
};

const rankingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetRealtimeRankingActions.SUCCESS: {
      const { ranking, rank_dict, start_rank } = action.payload;

      return {
        ...state,
        realtimeRanking: ranking,
        rankObj: rank_dict,
        startRank: start_rank
      };
    }
    default:
      return state;
  }
};

export function getRealtimeRanking() {
  return {
    type: GetRealtimeRankingActions,
    request: apis.ranking.getRealtimeRanking()
  };
}

export default rankingReducer;
