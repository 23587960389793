import { getAsyncActionTypes } from "src/stores/utils";
import apis from "src/apis";

const prefix = "session";

export const [
  SignInActions,
  GetUserInfo,
  AddUserPointActions,
  GetEventUserHistories,
] = [
  "SIGNIN",
  "GET_USER_INFO",
  "ADD_USER_POINT",
  "GET_EVENT_USER_HISTORIES",
].map((actionName) => getAsyncActionTypes(prefix, actionName));

const SessionActions = {
  SET_TOKEN: `${prefix}/SET_TOKEN`,
};

const initialState = {
  user: {},
  token: null,
  eventHistories: [],
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignInActions.SUCCESS:
      return { ...state, token: action.payload.token };
    case GetUserInfo.SUCCESS:
      return { ...state, user: action.payload.user };
    case SessionActions.SET_TOKEN:
      return { ...state, token: action.payload.token };
    case AddUserPointActions.SUCCESS:
      return { ...state, user: action.payload.user };
    case GetEventUserHistories.SUCCESS:
      const { eventHistories } = action.payload;
      return { ...state, eventHistories };
    default:
      return state;
  }
};

export function signIn(payload) {
  return {
    type: SignInActions,
    request: () => apis.session.signIn(payload),
  };
}

export function getUserInfo() {
  return {
    type: GetUserInfo,
    request: apis.session.getUserInfo(),
  };
}

export function setToken(payload) {
  return {
    type: SessionActions.SET_TOKEN,
    payload,
  };
}

export function addUserPoint(payload) {
  return {
    type: AddUserPointActions,
    request: apis.learning.addUserPoint(payload),
  };
}

export function getEventUserHistories(payload) {
  return {
    type: GetEventUserHistories,
    request: apis.event.getEventUserHistories(payload),
  };
}

export default sessionReducer;
