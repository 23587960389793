export const alphabetSet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const wordSet = [
  "and",
  "away",
  "big",
  "blue",
  "can",
  "come",
  "down",
  "find",
  "for",
  "funny",
  "go",
  "help",
  "the",
  "here",
  "it",
  "is",
  "me",
  "my",
  "hot",
  "one",
  "play",
  "red",
  "run",
  "said",
  "see",
  "all",
  "am",
  "are",
  "at",
  "ate",
  "black",
  "brown",
  "but",
  "came",
  "did",
  "do",
  "eat",
  "four",
  "get",
  "good",
  "have",
  "he",
  "into",
  "like",
  "must",
  "new",
  "no",
  "now",
  "on",
  "our",
  "out",
  "please",
  "pretty",
  "ran",
  "ride",
  "saw",
  "after",
  "again",
  "an",
  "any",
  "as",
  "ask",
  "by",
  "could",
  "every",
  "fly",
  "from",
  "give",
  "going",
  "had",
  "has",
  "her",
  "him",
  "how",
  "just",
  "know",
  "let",
  "live",
  "may",
  "of",
  "old",
  "once",
  "over",
  "put",
  "round",
  "some",
  "always",
  "around",
  "because",
  "been",
  "before",
  "best",
  "both",
  "buy",
  "call",
  "cold",
  "does",
  "don't",
  "fast",
  "first",
  "five",
  "found",
  "gave",
  "goes",
  "green",
  "its",
  "made",
  "many",
  "off",
  "or",
  "pull",
  "read",
  "right",
  "sing",
  "sit",
  "sleep",
  "tell",
  "their",
  "about",
  "better",
  "bring",
  "carry",
  "clean",
  "cut",
  "done",
  "draw",
  "drink",
  "eight",
  "fall",
  "far",
  "full",
  "got",
  "grow",
  "hold",
  "hot",
  "hurt",
  "if",
  "keep",
  "kind",
  "laugh",
  "long",
  "much",
  "myself",
  "never",
  "only",
  "own",
  "pick",
  "seven",
  "shall",
  "that",
  "there",
  "this",
  "soon",
  "so",
  "went",
  "what",
  "who",
  "will",
  "with",
  "when",
  "were",
  "us",
  "upon",
  "these",
  "those",
  "would",
  "your",
];
