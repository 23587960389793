import { getAsyncActionTypes } from "src/stores/utils";
import apis from "src/apis";

const prefix = "audio";

export const [GetAudioQuestionsActions] = ["GET_AUDIO_QUESTIONS"].map(
  (actionName) => getAsyncActionTypes(prefix, actionName)
);

const AudioActions = {
  RESET_STATE: `${prefix}/RESET_STATE`,
  SET_IDX: `${prefix}/SET_IDX`,
  SET_STEP: `${prefix}/SET_STEP`,
  SET_AUDIO_PLAYED: `${prefix}/SET_AUDIO_PLAYED`,
};

const initialState = {
  repeatMode: false,
  idx: -1,
  learningModule: {},
  questions: [],
  step: 0,
  audioPlayed: false,
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case AudioActions.RESET_STATE:
      return initialState;
    case AudioActions.SET_IDX:
      return { ...state, idx: action.idx };
    case GetAudioQuestionsActions.SUCCESS:
      return { ...state, ...action.payload };
    case AudioActions.SET_STEP:
      return { ...state, idx: -1, step: action.payload.step };
    case AudioActions.SET_AUDIO_PLAYED:
      return { ...state, audioPlayed: action.audioPlayed };
    default:
      return state;
  }
};

export function resetState() {
  return {
    type: AudioActions.RESET_STATE,
  };
}

export function setIdx(idx) {
  return {
    type: AudioActions.SET_IDX,
    idx,
  };
}

export function getAudioQuestions(payload) {
  return {
    type: GetAudioQuestionsActions,
    request: apis.audio.getAudioQuestions(payload),
  };
}

export function setStep(payload) {
  return {
    type: AudioActions.SET_STEP,
    payload,
  };
}

export function setAudioPlayed(audioPlayed) {
  return {
    type: AudioActions.SET_AUDIO_PLAYED,
    audioPlayed,
  };
}

export function getSttResult(payload) {
  return {
    type: GetAudioQuestionsActions,
    request: apis.audio.getAudioQuestions(payload),
  };
}

export default audioReducer;
