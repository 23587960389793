import { format } from "date-fns";

export const getDateFormatOrEmpty = (date, displayFormat) => {
  return date ? format(date, displayFormat) : "";
};

export const getDate = (date) => {
  const displayFormat = "yyyy.MM.dd";
  return getDateFormatOrEmpty(date, displayFormat);
};

export const checkValidDate = (startAt, endAt) => {
  const now = new Date();
  return now > new Date(startAt) && now < new Date(endAt);
};
