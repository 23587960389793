import React from "react";
import Anime from "react-anime";
import logoLeft from "src/assets/images/common/logo_left.png";
import logoRight from "src/assets/images/common/logo_right.png";
import "src/assets/css/base.css";

const animeLeftProps = {
  scale: [
    {
      value: 0,
      duration: 0,
    },
    {
      value: 1,
      duration: 1500,
    },
  ],
  loop: true,
};

const animeRightProps = {
  scale: [
    {
      value: 0,
      duration: 0,
    },
    {
      value: 1,
      duration: 1500,
    },
  ],
  loop: true,
};

const Loading = () => {
  return (
    <div id="load_wrapper" className="load_wrapper">
      <Anime {...animeLeftProps}>
        <img className="load_img_1" src={logoLeft} alt="" />
      </Anime>
      <Anime {...animeRightProps}>
        <img className="load_img_2" src={logoRight} alt="" />
      </Anime>
      <h1>Loading..</h1>
    </div>
  );
};

export default Loading;
