import beepMp3 from "src/assets/audio/beep.mp3";
import clockMp3 from "src/assets/audio/wait4_part.mp3";
import nextWav from "src/assets/audio/next.wav";
import readyMp3 from "src/assets/audio/ready.mp3";
import typingClickMp3 from "src/assets/audio/typing_click.wav";
import oopsMp3 from "src/assets/audio/oops.wav";
import perfectMp3 from "src/assets/audio/perfect.mp3";
import goodMp3 from "src/assets/audio/good.mp3";
import thatsOkMp3 from "src/assets/audio/that_s_ok.mp3";
import tryAgainMp3 from "src/assets/audio/try_again.mp3";
import homeBackgroundMp3 from "src/assets/audio/home_background.mp3";

export const beep = new Audio(beepMp3);
export const clock = new Audio(clockMp3);
export const next = new Audio(nextWav);
export const ready = new Audio(readyMp3);
export const typingClick = new Audio(typingClickMp3);
export const oops = new Audio(oopsMp3);
export const perfect = new Audio(perfectMp3);
export const good = new Audio(goodMp3);
export const thatsOk = new Audio(thatsOkMp3);
export const tryAgain = new Audio(tryAgainMp3);
export const homeBackground = new Audio(homeBackgroundMp3);

homeBackground.loop = true;
homeBackground.volume = 0.5;
clock.volume = 0.25;

export const playTypingClick = () => {
  typingClick.pause();
  typingClick.currentTime = 0;
  typingClick.play();
};
