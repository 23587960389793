import timerReducer from "src/stores/timer/timer.ducks";
import {getFetcherWithToken} from "src/utils/Fetcher";
import {getMediaUri} from "src/utils/media";

function getBooks() {
    return (token) =>
        getFetcherWithToken(token)
            .get("/book")
            .then((res) => {
                return {
                    ...res.data,
                    books: res.data.ss_books
                        .filter((book) => {
                            if (!book.book) {
                                return false;
                            }
                            return true;
                        })
                        .map((book) => {
                            const findHistory = res.data.ss_learning_histories.find((history) => {
                                history.ss_book_id === book.book.id;
                            });
                            return {
                                ...book,
                                book: {
                                    ...book.book,
                                    thumbnail: book.book.thumbnail ? getMediaUri(book.book.thumbnail.path) : null,
                                },
                                learning_history: findHistory || {},
                            };
                        }),
                };
            });
}

function getBookLearnings({bookId}) {
    return (token) =>
        getFetcherWithToken(token)
            .get(`/book/${bookId}/learnings`)
            .then((res) => {
                return {
                    ...res.data,
                    learnings: res.data.ss_learnings.filter((item) => item.ss_learning_modules.length > 0),
                };
            })
            .then((res) => {
                return {
                    learnings: res.learnings.map((learning) => {
                        return {
                            ...learning,
                            ss_learning_modules: learning.ss_learning_modules
                                .filter((learningModule) => {
                                    return learningModule.ss_exercise;
                                })
                                .map((learningModule) => {
                                    return {
                                        ...learningModule,
                                        learning_history: res.ss_learning_histories.find((learningHistory) => learningHistory.ss_learning_module === learningModule.id) || null,
                                    };
                                }),
                        };
                    }),
                };
            });
}

export default {
    getBooks,
    getBookLearnings,
};
