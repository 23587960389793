import { getFetcherWithToken } from "src/utils/Fetcher";
import { getMediaUri } from "src/utils/media";

function getEventType({ bonusType, point }) {
  if (bonusType === "normal") {
    return `${bonusType}_${point}`;
  } else {
    return bonusType;
  }
}

function checkEvent() {
  return (token) =>
    getFetcherWithToken(token)
      .get(`/event`)
      .then((res) => res.data);
}

function getEventHistories() {
  return (token) =>
    getFetcherWithToken(token)
      .get(`/event/history`)
      .then((res) => {
        const { event_histories, event } = res.data;
        return {
          histories: event_histories,
          event: {
            ...event,
            image_uri: event.image_uri ? getMediaUri(event.image_uri) : null,
          },
        };
      });
}

function getEventUserHistories() {
  return (token) =>
    getFetcherWithToken(token)
      .get(`/event/user/history`)
      .then((res) => {
        const { event_histories } = res.data;
        return {
          eventHistories: event_histories,
        };
      });
}

export default {
  checkEvent,
  getEventHistories,
  getEventUserHistories,
};
