import audio from "./audio";
import typing from "./typing";
import video from "./video";
import book from "./book";
import session from "./session";
import ranking from "./ranking";
import learning from "./learning";
import event from "./event";
import quiz from "./quiz";
import bug from "./bug";
import file from "./file";

export default {
  audio,
  typing,
  video,
  book,
  session,
  ranking,
  learning,
  event,
  quiz,
  bug,
  file,
};
