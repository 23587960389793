// 'word_speaking','sentence_speaking','text_speaking','word_typing','sentence_typing','pattern','test','media'
export const getLearningModuleUrl = ({
  bookId,
  learningId,
  moduleId,
  moduleType,
}) => {
  let tailUrl = "";
  switch (moduleType) {
    case "word_speaking":
      tailUrl = "/audio/word";
      break;
    case "sentence_speaking":
      tailUrl = "/audio/sentence";
      break;
    case "text_speaking":
      tailUrl = "/audio/reading";
      break;
    case "pattern":
      tailUrl = "/audio/pattern";
      break;
    case "word_typing":
      tailUrl = "/typing/word";
      break;
    case "sentence_typing":
      tailUrl = "/typing/sentence";
      break;
    case "media":
      tailUrl = "/media";
      break;
    case "test":
      tailUrl = "/quiz";
      break;
  }
  return `/book/${bookId}/learning/${learningId}/module/${moduleId}${tailUrl}`;
};
