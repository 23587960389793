import { getAsyncActionTypes } from "src/stores/utils";
import apis from "src/apis";

const prefix = "event";

export const [CheckEvent, GetEventHistories] = [
  "CHECK_EVENT",
  "GET_EVENT_HISTORIES",
].map((actionName) => getAsyncActionTypes(prefix, actionName));

const EventActions = {
  SET_CHECKING_EVENT: `${prefix}/SET_CHECKING_EVENT`,
  HIDE_EVENT: `${prefix}/HIDE_EVENT`,
  SHOW_BONUS_TIME: `${prefix}/SHOW_BONUS_TIME`,
};

const initialState = {
  checking: null,
  show: false, //start, stop
  bonusType: "mega",
  event: {},
  histories: [],
  point: 0,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EventActions.SET_CHECKING_EVENT:
      const { checkingEvent } = action.payload;
      return { ...state, checking: checkingEvent };
    case EventActions.HIDE_EVENT:
      return { ...state, show: false };
    case CheckEvent.SUCCESS: {
      const { event } = action.payload;

      return {
        ...state,
        bonusType: event.bonus_type,
        point: event.point,
        show: "bonus_type" in event,
        checking: false,
      };
    }
    case GetEventHistories.SUCCESS: {
      const { histories, event } = action.payload;
      return { ...state, histories, event };
    }
    case EventActions.SHOW_BONUS_TIME: {
      return {
        ...state,
        bonusType: "mega",
        point: 1000,
        show: true,
        checking: false,
      };
    }
    default:
      return state;
  }
};

export function setCheckingEvent(payload) {
  return {
    type: EventActions.SET_CHECKING_EVENT,
    payload,
  };
}

export function hideEvent() {
  return {
    type: EventActions.HIDE_EVENT,
  };
}

export function checkEvent() {
  return {
    type: CheckEvent,
    request: apis.event.checkEvent(),
  };
}

export function getEventHistories() {
  return {
    type: GetEventHistories,
    request: apis.event.getEventHistories(),
  };
}

export function showBonusTime() {
  return {
    type: EventActions.SHOW_BONUS_TIME,
  };
}

export default eventReducer;
