import { getFetcherWithToken } from "src/utils/Fetcher";

function addUserPoint(payload) {
  const { point } = payload;

  return (token) =>
    getFetcherWithToken(token)
      .post(`/user/point/add`, { point })
      .then((res) => res.data);
}

function addLearningHistory(payload) {
  const { bookId, learningModuleId, finished, correctScore, totalScore, testJson } = payload;
  return (token) =>
    getFetcherWithToken(token)
      .post(`/learning/history/add`, { bookId, learningModuleId, finished, correct_score: correctScore, total_score: totalScore, test_json: testJson })
      .then((res) => res.data);
}

function getLearningHistory(payload) {
  const { learningModuleId } = payload;
  return (token) =>
    getFetcherWithToken(token)
      .get(`/learning/history?learning_module_id=${learningModuleId}`)
      .then((res) => res.data);
}

export default {
  addUserPoint,
  addLearningHistory,
  getLearningHistory,
};
