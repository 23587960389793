export function isPureObject(input) {
  return (
    input !== null &&
    typeof input === "object" &&
    Object.getPrototypeOf(input).isPrototypeOf(Object)
  );
}

export function cloneDeep(obj) {
  // File
  if (obj instanceof File) {
    return new File([obj], obj.name, { type: obj.type });
  }

  // pure Object
  if (isPureObject(obj)) {
    const clone = {};
    Object.entries(obj).forEach(([key, value]) => {
      clone[key] = cloneDeep(value);
    });
    return clone;
  }

  // Array
  if (Array.isArray(obj)) {
    return obj.map((e) => cloneDeep(e));
  }

  return obj;
}
