import { getAsyncActionTypes } from "src/stores/utils";
import apis from "src/apis";

const prefix = "video";

export const [GetVideosActions] = [
  "GET_VIDEOS",
].map((actionName) => getAsyncActionTypes(prefix, actionName));

const VideoActions = {
  TOGGLE_PLAYING: `${prefix}/TOGGLE_PLAYING`,
};

const initialState = {
  videos: [],
  playing: false
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetVideosActions.SUCCESS:
      return { ...state, ...action.payload };
    case VideoActions.TOGGLE_PLAYING:
      return { ...state, playing: !state.playing };      
    default:
      return state;
  }
};

export function togglePlaying() {
  return {
    type: VideoActions.TOGGLE_PLAYING,
  };
}

export function getVideos(payload) {
  return {
    type: GetVideosActions,
    request: apis.video.getVideos(payload),
  };
}

export default videoReducer;
