import fetcher, { getFetcherWithToken } from "src/utils/Fetcher";

function signIn(payload) {
  return fetcher.post("/signin", payload).then((res) => res.data);
}

function getUserInfo() {
  return (token) =>
    getFetcherWithToken(token)
      .get("/user")
      .then((res) => res.data);
}

export default {
  signIn,
  getUserInfo,
};
