import JwtDecode from "jwt-decode";

export function decodeJwtToken(token) {
  if (!token) {
    return {};
  }
  try {
    return JwtDecode(token);
  } catch (e) {
    return {};
  }
}

export function getUserName(user) {
  if (Object.keys(user).length === 0) {
    return "";
  }

  let userName = user.mb_name;

  if (user.user && user.user.name_eng) {
    userName += `(${user.user.name_eng})`;
  }

  return userName;
}

export function getUserKorName(user) {
  if (Object.keys(user).length === 0) {
    return "";
  }

  let userName = user.mb_name;

  return userName;
}

const Avatars = [
  "/static/media/avatar_1.ce6bcc7a.png",
  "/static/media/avatar_2.2033e449.png",
  "/static/media/avatar_3.a1ac4891.png",
  "/static/media/avatar_4.813cf2a3.png",
  "/static/media/avatar_5.bed06808.png",
];

export function getUserAvatarSrc(user) {
  if (Object.keys(user).length === 0 || !user.user_point) {
    return Avatars[0];
  }

  const { point } = user.user_point;

  if (point < 501) {
    return Avatars[0];
  } else if (point < 5001) {
    return Avatars[1];
  } else if (point < 15001) {
    return Avatars[2];
  } else if (point < 30001) {
    return Avatars[3];
  } else {
    return Avatars[4];
  }
}

export function getUserAvatarType(user) {
  if (Object.keys(user).length === 0 || !user.user_point) {
    return Avatars[0];
  }

  const { point } = user.user_point;

  if (point < 501) {
    return 1;
  } else if (point < 5001) {
    return 2;
  } else if (point < 15001) {
    return 3;
  } else if (point < 30001) {
    return 4;
  } else {
    return 5;
  }
}
