import { clock } from "src/utils/audio";

const prefix = "timer";

const TimerActions = {
  RESET_STATE: `${prefix}/RESET_STATE`,
  START_TIMER: `${prefix}/START_TIMER`,
  SET_TIMEVALUE: `${prefix}/SET_TIMEVALUE`,
  END_TIMER: `${prefix}/END_TIMER`,
  SET_TIMER_STATUS: `${prefix}/SET_TIMER_STATUS`,
};

const initialState = {
  status: "stop", //start, stop, end
  endTime: 0,
  totalTime: 0,
  timeValue: 0,
};

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TimerActions.RESET_STATE:
      return initialState;
    case TimerActions.START_TIMER:
      // clock.pause(); // 시계음성 정지
      // clock.currentTime = 0;
      // clock.play();
      return { ...state, status: "start", ...action.payload };
    case TimerActions.SET_TIMEVALUE:
      const { timeValue } = action.payload;
      if (timeValue > 0) {
        return { ...state, timeValue };
      } else {
        return { ...state, status: "stop", timeValue };
      }
    case TimerActions.END_TIMER:
      return { ...state, status: "end", timeValue: 0 };
    case TimerActions.SET_TIMER_STATUS:
      const { status } = action.payload;
      return { ...state, status };
    default:
      return state;
  }
};

export function resetState() {
  return {
    type: TimerActions.RESET_STATE,
  };
}

export function startTimer(payload) {
  return {
    type: TimerActions.START_TIMER,
    payload,
  };
}

export function setTimeValue(payload) {
  return {
    type: TimerActions.SET_TIMEVALUE,
    payload,
  };
}

export function endTimer() {
  return {
    type: TimerActions.END_TIMER,
  };
}

export function setTimerStatus(payload) {
  return {
    type: TimerActions.SET_TIMER_STATUS,
    payload,
  };
}

export default timerReducer;
