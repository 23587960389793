import { getFetcherWithToken } from "src/utils/Fetcher";

function sendBugReport(payload) {
  return (token) =>
    getFetcherWithToken(token)
      .post(`/bug/add`, payload)
      .then((res) => {
        return;
      });
}

export default {
  sendBugReport,
};
