import {getAsyncActionTypes} from "src/stores/utils";
import apis from "src/apis";

const prefix = "learning";

export const [GetBooksActions, GetBookLearningsActions, AddLearningHistory, GetLearningHistory] = ["GET_BOOKS", "GET_BOOK_LEARNINGS", "ADD_LEARNING_HISTORY", "GET_LEARNING_HISTORY"].map((actionName) => getAsyncActionTypes(prefix, actionName));

const LearningActions = {
    TOGGLE_REPEAT_MODE: `${prefix}/TOGGLE_REPEAT_MODE`,
    TOGGLE_PLAY_MODE: `${prefix}/TOGGLE_PLAY_MODE`,
    SET_RECORD_MODE: `${prefix}/SET_RECORD_MODE`,
    SET_SPEED: `${prefix}/SET_SPEED`,
    SET_DURATION: `${prefix}/SET_DURATION`,
    SET_MODULE: `${prefix}/SET_MODULE`,
    SET_SHOW_POINT_UP: `${prefix}/SET_SHOW_POINT_UP`,
    CLEAR_LEARNINGS: `${prefix}/CLEAR_LEARNINGS`,
    TOGGLE_CLASS_MODE: `${prefix}/TOGGLE_CLASS_MODE`,
};

const initialState = {
    books: [],
    learnings: [],
    repeatMode: false,
    playMode: true,
    recordMode: false,
    classMode: "book", // "book", "lesson"
    speed: 100,
    duration: 3.5,
    module: "",
    showPointUp: false,
    point: "+1",
    last_learning_history: null,
    hasLearningHistory: false,
};

const learningReducer = (state = initialState, action) => {
    switch (action.type) {
        case LearningActions.TOGGLE_REPEAT_MODE:
            return {...state, repeatMode: !state.repeatMode};
        case LearningActions.TOGGLE_PLAY_MODE:
            return {...state, playMode: !state.playMode};
        case LearningActions.SET_RECORD_MODE:
            const {recordMode} = action.payload;
            return {...state, recordMode};
        case LearningActions.TOGGLE_CLASS_MODE:
            if (state.classMode === "book") {
                return {...state, classMode: "lesson"};
            } else {
                return {...state, classMode: "book"};
            }
        case LearningActions.SET_SPEED:
            const {speed} = action.payload;
            return {...state, speed};
        case LearningActions.SET_DURATION:
            const {duration} = action.payload;
            return {...state, duration};
        case LearningActions.SET_MODULE:
            const {module} = action.payload;
            return {...state, module};
        case GetBooksActions.SUCCESS: {
            const {books, last_learning_history} = action.payload;
            return {...state, books, last_learning_history};
        }
        case GetBookLearningsActions.SUCCESS: {
            const {learnings} = action.payload;
            return {...state, learnings};
        }
        case LearningActions.SET_SHOW_POINT_UP:
            const {showPointUp, point} = action.payload;
            return {...state, showPointUp, point};
        case LearningActions.CLEAR_LEARNINGS:
            return {...state, learnings: []};
        case AddLearningHistory.SUCCESS: {
            const {ss_learning_history} = action.payload;
            const newLearnings = state.learnings.map((learning) => {
                return {
                    ...learning,
                    ss_learning_modules: learning.ss_learning_modules.map((learningModule) => {
                        if (learningModule.id === ss_learning_history.ss_learning_module) {
                            return {
                                ...learningModule,
                                learning_history: ss_learning_history,
                            };
                        } else {
                            return learningModule;
                        }
                    }),
                };
            });
            return {...state, learnings: newLearnings};
        }
        case GetLearningHistory.SUCCESS: {
            const {ss_learning_history} = action.payload;

            return {
                ...state,
                hasLearningHistory: !(Object.keys(ss_learning_history).length === 0 && ss_learning_history.constructor === Object),
            };
        }
        default:
            return state;
    }
};

export function toggleRepeat() {
    return {
        type: LearningActions.TOGGLE_REPEAT_MODE,
    };
}

export function togglePlayMode() {
    return {
        type: LearningActions.TOGGLE_PLAY_MODE,
    };
}

export function setRecordMode(payload) {
    return {
        type: LearningActions.SET_RECORD_MODE,
        payload,
    };
}

export function toggleClassMode() {
    return {
        type: LearningActions.TOGGLE_CLASS_MODE,
    };
}

export function setSpeed(payload) {
    return {
        type: LearningActions.SET_SPEED,
        payload,
    };
}

export function setDuration(payload) {
    return {
        type: LearningActions.SET_DURATION,
        payload,
    };
}

export function getBooks() {
    return {
        type: GetBooksActions,
        request: apis.book.getBooks(),
    };
}

export function getBookLearnings(payload) {
    return {
        type: GetBookLearningsActions,
        request: apis.book.getBookLearnings(payload),
    };
}

export function setModule(payload) {
    return {
        type: LearningActions.SET_MODULE,
        payload,
    };
}

export function setShowPointUp(payload) {
    return {
        type: LearningActions.SET_SHOW_POINT_UP,
        payload,
    };
}

export function clearLearnings() {
    return {
        type: LearningActions.CLEAR_LEARNINGS,
    };
}

export function addLearningHistory(payload) {
    return {
        type: AddLearningHistory,
        request: apis.learning.addLearningHistory(payload),
    };
}

export function getLearningHistory(payload) {
    return {
        type: GetLearningHistory,
        request: apis.learning.getLearningHistory(payload),
    };
}

export default learningReducer;
