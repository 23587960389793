import axios from "axios";

const NETWORK_TIMEOUT = 100000;

export const API_URL = "https://student-api.epamus.com";
export const RANKING_API_URL = "https://student-ranking.epamus.com";

const commonConfig = {
    baseURL: API_URL,
    timeout: NETWORK_TIMEOUT,
    headers: {"Content-Type": "application/json"},
};

export const createFetcher = (options) => {
    return axios.create({
        ...commonConfig,
        ...options,
    });
};

export const fetcher = createFetcher({
    baseURL: API_URL,
});

export const getFetcherWithToken = (token) => {
    const instance = createFetcher({baseURL: API_URL});
    instance.defaults.headers.common.Authorization = `JWT ${token}`;
    return instance;
};

export const getRankingFetcherWithToken = (token) => {
    const instance = createFetcher({baseURL: RANKING_API_URL});
    instance.defaults.headers.common.Authorization = `JWT ${token}`;
    return instance;
};

export default fetcher;
