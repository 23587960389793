import {
  getFetcherWithToken,
  getRankingFetcherWithToken,
} from 'src/utils/Fetcher';

function getRankings({ page, mb_no }) {
  return (token) =>
    getRankingFetcherWithToken(token)
      .get(`/ranking?page=${page}&mb_no=${mb_no}`)
      .then((res) => res.data);
}

function getRealtimeRanking() {
  return (token) =>
    getRankingFetcherWithToken(token)
      .get(`/ranking/realtime`)
      .then((res) => res.data);
}

export default {
  getRankings,
  getRealtimeRanking,
};
