import {getFetcherWithToken} from "src/utils/Fetcher";
import {getMediaUri} from "src/utils/media";

function getQuizQuestions(payload) {
    return (token) =>
        getFetcherWithToken(token)
            .get(`/question?module_id=${payload.moduleId}`)
            .then((res) => {
                return {
                    questions: res.data.ss_questions.map((item) => {
                        return {
                            ...item,
                            audio: item.audio ? new Audio(getMediaUri(item.audio.path)) : null,
                            image: item.image ? getMediaUri(item.image.path) : "",
                            eng: item.eng ? item.eng.replace(/ +/g, " ").trim() : null,
                        };
                    }),
                };
            });
}

export default {
    getQuizQuestions,
};
