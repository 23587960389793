import { getFetcherWithToken } from "src/utils/Fetcher";

function submitFile(payload) {
  const { category, moduleId, file } = payload;
  let formData = new FormData();

  formData.append('category', category);
  formData.append('module_id', moduleId);
  // formData.append('file', file, "blob");

  return (token) =>
    getFetcherWithToken(token)
      .post(`/file/submit`, formData)
      .then((res) => res.data);
}

export default {
  submitFile
};
