import Cookies from "js-cookie";

function asyncMiddleware(store) {
  return next => action => {
    const isRequestAction = typeof action.type === "object" && !!action.request;
    const state = store.getState();
    let token = state.session.token;
    if (!token) {
      token = Cookies.get("token");
    }

    if (!isRequestAction) {
      return next(action);
    }
    const { request } = action;
    const { REQUEST, SUCCESS, FAILURE } = action.type;

    next({ type: REQUEST, loading: true });

    return request(token)
      .then(response =>
        next({
          type: SUCCESS,
          loading: false,
          payload: response
        })
      )
      .catch(e => {
        next({
          type: FAILURE,
          loading: false,
          payload: { error: e }
        });

        if (e.response && e.response.data && e.response.data.code) {
          console.error(e.response.data);
        }
        return Promise.reject(e);
      });
  };
}

export default asyncMiddleware;
