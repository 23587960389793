import { getAsyncActionTypes } from 'src/stores/utils';
import apis from 'src/apis';
import { getSpecialLetter } from 'src/utils/learning';
import { cloneDeep } from 'src/utils/copy';
import learning from 'src/apis/learning';

const prefix = 'typing';

export const [GetTypingQuestionsActions] = ['GET_TYPING_QUESTIONS'].map(
  (actionName) => getAsyncActionTypes(prefix, actionName)
);

const TypingActions = {
  RESET_STATE: `${prefix}/RESET_STATE`,
  SET_IDX: `${prefix}/SET_IDX`,
  APPEND_ANSWER: `${prefix}/APPEND_ANSWER`,
  REMOVE_ANSWER: `${prefix}/REMOVE_ANSWER`,
  REMOVE_LAST_ANSWER: `${prefix}/REMOVE_LAST_ANSWER`,
  NEXT_QUESTION: `${prefix}/NEXT_QUESTION`,
  SET_STEP: `${prefix}/SET_STEP`,
  SET_TYPE: `${prefix}/SET_TYPE`,
  RESET_TYPING: `${prefix}/RESET_TYPING`,
  ADD_INCORRECT_ANSWERS: `${prefix}/ADD_INCORRECT_ANSWERS`,
};

const initialState = {
  type: 'word',
  idx: -1,
  learningModule: {},
  questions: [],
  incorrectAnswers: [],
  answer: '',
  step: 0,
};

function getInitialAnswer(type, step, question) {
  if (type === 'word') {
    return '';
  } else {
    if (step === 1) {
      const questionArr = question
        ? cloneDeep(question.eng.split(' ').filter((item) => item !== ''))
        : [];
      const questionLen = questionArr.length;
      for (let i = 0; i < parseInt(questionLen / 2, 10); ) {
        const rand = Math.floor(Math.random() * questionLen);
        if (questionArr[rand] !== '') {
          questionArr[rand] = '';
          i++;
        }
      }

      return questionArr.join(' ');
    }

    return '';
  }
}

const typingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TypingActions.RESET_STATE:
      return initialState;
    case TypingActions.SET_IDX:
      return { ...state, idx: action.idx };
    case GetTypingQuestionsActions.SUCCESS:
      return { ...state, ...action.payload };
    case TypingActions.APPEND_ANSWER: {
      const { type, answer, questions, incorrectAnswers, idx, step } = state;
      const question =
        step < 3 ? questions[idx].eng : incorrectAnswers[idx].eng;
      const { letter } = action.payload;

      if (type === 'word') {
        const questionLetter = question.replace(/ /g, '');
        const answerLetter = answer.replace(/ /g, '');
        if (
          answerLetter.length < questionLetter.length ||
          answerLetter.length === 0
        ) {
          const nextQuestionLetter = question[answer.length + 1];

          return {
            ...state,
            answer: `${answer}${letter}${getSpecialLetter(nextQuestionLetter)}`,
          };
        }
      } else {
        const questionArr = question.split(' ').filter((item) => item !== '');
        const answerArr = cloneDeep(answer.split(' '));

        if (step === 1) {
          const targetIdx = answerArr.findIndex((entry) => entry === '');
          answerArr[targetIdx] = letter;
          return {
            ...state,
            answer: answerArr.join(' '),
          };
        } else {
          if (answerArr.length < questionArr.length || answer.length === 0) {
            return {
              ...state,
              answer:
                answer.length > 0
                  ? `${answer} ${letter}`
                  : `${answer}${letter}`,
            };
          }
        }
      }
      return state;
    }
    case TypingActions.REMOVE_ANSWER: {
      const { answer, questions, idx: questionIdx } = state;
      const { idx } = action.payload;

      // 마지막 정답까지 모두 입력된 경우에는 작동하지 않음
      const answerWordSplitArr = answer
        .split(' ')
        .filter((entry) => entry !== '');
      const question = questions[questionIdx].eng;
      const questionWordSplitArr = question
        .split(' ')
        .filter((item) => item !== '');
      const answerLen = answerWordSplitArr.length;
      const questionLength = questionWordSplitArr.length;
      if (answerLen === questionLength) {
        return state;
      }

      const answerArr = cloneDeep(answer.split(' '));
      answerArr[idx] = '';
      return { ...state, answer: answerArr.join(' ') };
    }
    case TypingActions.REMOVE_LAST_ANSWER: {
      const { type, answer, questions, idx, step } = state;
      const answerLen = answer.length;
      const question =
        step < 3 ? questions[idx].eng : incorrectAnswers[idx].eng;

      if (type === 'word') {
        // 마지막 글자까지 모두 입력된 경우에는 작동하지 않음
        const questionLen = question.length;
        if (answerLen === questionLen) {
          return state;
        }

        return { ...state, answer: answer.substring(0, answerLen - 1) };
      } else {
        // 마지막 정답까지 모두 입력된 경우에는 작동하지 않음
        const answerWordSplitArr = answer
          .split(' ')
          .filter((entry) => entry !== '');
        const questionWordSplitArr = question
          .split(' ')
          .filter((item) => item !== '');
        const answerLen = answerWordSplitArr.length;
        const questionLength = questionWordSplitArr.length;
        if (answerLen === questionLength) {
          return state;
        }

        const answerArr = cloneDeep(answer.split(' '));
        if (answerArr.length > 0) {
          answerArr.pop();
          return {
            ...state,
            answer: answerArr.join(' '),
          };
        }
      }
    }
    case TypingActions.NEXT_QUESTION: {
      const { idx, questions, step, type } = state;
      if (step === 1) {
        questions[idx + 1] && questions[idx + 1].audio.play();
      }
      return {
        ...state,
        idx: state.idx + 1,
        answer: getInitialAnswer(type, step, questions[idx + 1]),
      };
    }
    case TypingActions.SET_STEP: {
      const { type, questions } = state;
      const { step } = action.payload;

      if (step === 1) {
        return {
          ...state,
          idx: -1,
          answer: getInitialAnswer(type, step, questions[0]),
          step: step,
        };
      } else {
        return { ...state, idx: -1, answer: '', step: step };
      }
    }
    case TypingActions.SET_TYPE:
      const { type } = action.payload;

      return { ...state, type };
    case TypingActions.RESET_TYPING:
      return initialState;
    case TypingActions.ADD_INCORRECT_ANSWERS: {
      const { type, step } = state;
      const { question } = action.payload;

      return {
        ...state,
        incorrectAnswers: [...state.incorrectAnswers, question],
        answer: getInitialAnswer(type, step, question),
      };
    }
    default:
      return state;
  }
};

export function resetState() {
  return {
    type: TypingActions.RESET_STATE,
  };
}

export function setIdx(idx) {
  return {
    type: TypingActions.SET_IDX,
    idx,
  };
}

export function getTypingQuestions(payload) {
  return {
    type: GetTypingQuestionsActions,
    request: apis.typing.getTypingQuestions(payload),
  };
}

export function appendAnswer(payload) {
  return {
    type: TypingActions.APPEND_ANSWER,
    payload,
  };
}

export function removeLastAnswer() {
  return {
    type: TypingActions.REMOVE_LAST_ANSWER,
  };
}

export function nextQuestion() {
  return {
    type: TypingActions.NEXT_QUESTION,
  };
}

export function setStep(payload) {
  return {
    type: TypingActions.SET_STEP,
    payload,
  };
}

export function setType(payload) {
  return {
    type: TypingActions.SET_TYPE,
    payload,
  };
}

export function resetTyping() {
  return {
    type: TypingActions.RESET_TYPING,
  };
}

export function addIncorrectAnswers(payload) {
  return {
    type: TypingActions.ADD_INCORRECT_ANSWERS,
    payload,
  };
}

export function removeAnswer(payload) {
  return {
    type: TypingActions.REMOVE_ANSWER,
    payload,
  };
}

export default typingReducer;
