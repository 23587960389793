import { alphabetSet, wordSet } from "src/constants/typing";

const specialLetters = [" ", "-"];

export const getSpecialLetter = (letter) => {
  const findSpecial = specialLetters.find(
    (specialLetter) => letter === specialLetter
  );
  return findSpecial || "";
};

export const isAlphabet = (letter) => {
  return alphabetSet.indexOf(letter) > -1;
};

export const getRandomSpel = () => {
  return alphabetSet[Math.floor(getRandomArbitrary(0, 25))];
};

export const getRandomArbitrary = (min, max) => {
  return Math.random() * (max + 1 - min) + min;
};

export const getRandomWord = () => {
  return wordSet[Math.floor(getRandomArbitrary(0, 166))];
};
