import React, {useMemo} from "react";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {toggleClassMode} from "src/stores/learning/learning.ducks";
import {getUserName, getUserAvatarSrc} from "src/utils/session";
import withAuth from "src/components/withAuth";
import EventPopup from "src/components/Module/EventPopup";
import {decodeJwtToken} from "src/utils/session";

import iconMyB from "src/assets/images/mobile/icon_my_b.png";
import iconRankB from "src/assets/images/mobile/icon_rank_b.png";
import iconHomeB from "src/assets/images/mobile/icon_home_b.png";
import iconOutB from "src/assets/images/mobile/icon_out_b.png";
import iconSildeRight from "src/assets/images/mobile/m_slide_btn_right.png";
import iconSildeLeft from "src/assets/images/mobile/m_slide_btn_left.png";

const HomeBase = ({children, title = ""}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {user, mode, token} = useSelector((state) => ({
        user: state.session.user,
        mode: state.learning.classMode,
        token: state.session.token,
    }));
    const userName = useMemo(() => getUserName(user), [user]);
    const userAvatarSrc = useMemo(() => getUserAvatarSrc(user), [user]);

    // 모바일 메뉴 오픈
    const openMobileMenu = () => {
        document.querySelectorAll(".m_menu_wrapper")[0].classList.add("active");
    };

    //모바일 메뉴 닫기
    const closeMobileMenu = () => {
        document.querySelectorAll(".m_menu_wrapper")[0].classList.remove("active");
    };

    const handleClickLogout = () => {
        const {is_whale} = decodeJwtToken(token);
        if (is_whale === "Y") {
            location.href = "/whale";
        } else {
            location.href = "/";
        }
        // const loginType = localStorage.getItem("loginType");
        // if (loginType) {
        //     if (loginType == "whale") {
        //         location.href = "/whale";
        //     } else if (loginType == "segim") {
        //         location.href = "/";
        //     }
        // } else {
        //     location.href = "/";
        // }
    };

    return (
        <div className="wrap">
            <EventPopup />
            <div className="page_header">
                <div className="user_left">
                    <p
                        className="user_photo"
                        style={{
                            backgroundImage: `url(${userAvatarSrc})`,
                            backgroundColor: "#fff",
                        }}
                    ></p>
                    <div className="col">
                        <h1 className="user_name">{userName}</h1>
                        <div className="row">
                            <p>point</p>
                            <span className="dot_number">{user.user_point ? user.user_point.point : 0}</span>
                        </div>
                    </div>
                </div>
                <div className="page_name">
                    <h1>{title}</h1>
                </div>
                <div className="btn_wrapper">
                    <ul className="btn_list">
                        {history.location.pathname === "/home" ? (
                            <>
                                <li className="rank">
                                    <a onClick={() => history.push("/ranking")}></a>
                                </li>
                                <li className="avatar">
                                    <a onClick={() => history.push("/my")}></a>
                                </li>
                                <li className="logoff">
                                    <a onClick={handleClickLogout}></a>
                                </li>
                            </>
                        ) : (
                            <li className="home">
                                <a onClick={() => history.push("/home")}></a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className="page_content">
                {history.location.pathname.search("/book/") > -1 && (
                    <a
                        className="m_page_move"
                        style={
                            mode === "book"
                                ? {
                                      backgroundImage: `url(${iconSildeRight})`,
                                      right: "1rem",
                                      left: "unset",
                                  }
                                : {
                                      backgroundImage: `url(${iconSildeLeft})`,
                                      right: "unset",
                                      left: "1rem",
                                  }
                        }
                        onClick={() => dispatch(toggleClassMode())}
                    ></a>
                )}
                <div className="book_img">
                    <ul className="book_img_list background_blue">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <div className="page_content_inner background_blue">{children}</div>
            </div>
            <div className="m_menu_wrapper">
                <p onClick={openMobileMenu} className="m_menu_icon"></p>
                <div className="m_menu_inner">
                    <div className="top">
                        <p
                            className="m_my_icon"
                            style={{
                                backgroundImage: `url(${userAvatarSrc})`,
                                backgroundColor: "#fff",
                            }}
                        ></p>
                        <div>
                            <h1>{userName}</h1>
                            <div>
                                <h1>point</h1>
                                <h2>{user.user_point ? user.user_point.point : 0}</h2>
                            </div>
                        </div>
                        <div onClick={closeMobileMenu} className="m_menu_off"></div>
                    </div>
                    <div className="bottom">
                        <ul className="bottom_list">
                            <li onClick={() => history.push("/my")}>
                                <img src={iconMyB} alt="" />
                                <h1>마이클라스</h1>
                            </li>
                            <li onClick={() => history.push("/ranking")}>
                                <img src={iconRankB} alt="" />
                                <h1>랭킹</h1>
                            </li>
                            <li onClick={() => history.push("/home")}>
                                <img src={iconHomeB} alt="" />
                                <h1>홈</h1>
                            </li>
                            <li onClick={handleClickLogout}>
                                <img src={iconOutB} alt="" />
                                <h1>로그아웃</h1>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="background_black"></div>
            </div>
        </div>
    );
};

export default withAuth(HomeBase);
