import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getUserInfo, setToken } from "src/stores/session/session.ducks";

const withAuth = (WrappedComponent) => {
  const WrapperComponent = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [cookies] = useCookies(["token"]);
    const { user, token } = useSelector((state) => ({
      user: state.session.user,
      token: state.session.token,
    }));

    useEffect(() => {
      if (!token) {
        if (cookies.token) {
          dispatch(setToken({ token: cookies.token }));
        } else {
          history.push("/");
        }
      } else {
        if (Object.keys(user).length === 0) {
          dispatch(getUserInfo());
        }
      }
    }, [user, token]);

    return <WrappedComponent {...props} />;
  };

  return WrapperComponent;
};

export default withAuth;
