import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {hideEvent} from "src/stores/event/event.ducks";

let timer;

const EventPopup = () => {
    const {user, token, show, bonusType, point} = useSelector((state) => ({
        user: state.session.user,
        token: state.session.token,
        show: state.event.show,
        bonusType: state.event.bonusType,
        point: state.event.point,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        const handleMessage = (e) => {
            if (e.data === "onEnd") {
                if (!timer) {
                    timer = setTimeout(function () {
                        timer = null;
                        dispatch(hideEvent());
                        console.log("get message for iframe");
                    }, 200);
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <>
            {show && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 99999,
                    }}
                >
                    <iframe src={`https://student-api.epamus.com/event/show?bonus_type=${bonusType}&point=${point}`} frameBorder="0" allow="autoplay" style={{width: "100%", height: "100%"}}></iframe>
                </div>
            )}
        </>
    );
};

export default EventPopup;
