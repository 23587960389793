import { getFetcherWithToken } from "src/utils/Fetcher";

import { getMediaUri } from "src/utils/media";


function getVideos(payload) {
  return (token) =>
    getFetcherWithToken(token)
      .get(`/question?module_id=${payload.moduleId}`)
      .then((res) => {
        return {
          videos: res.data.ss_questions.map((item) => {
            return {
              ...item,
              video: item.video ? getMediaUri(item.video.path): null,
            };
          }),
        };
      });
}

export default {
  getVideos,
};
