const prefix = "popup";

const PopupActions = {
  SHOW_POPUP: `${prefix}/SHOW_POPUP`,
  HIDE_POPUP: `${prefix}/HIDE_POPUP`,
  SHOW_END_POPUP: `${prefix}/SHOW_END_POPUP`,
  HIDE_END_POPUP: `${prefix}/HIDE_END_POPUP`,
  SHOW_BUG_POPUP: `${prefix}/SHOW_BUG_POPUP`,
  HIDE_BUG_POPUP: `${prefix}/HIDE_BUG_POPUP`,
  SHOW_EVAL_POPUP: `${prefix}/SHOW_EVAL_POPUP`,
  HIDE_EVAL_POPUP: `${prefix}/HIDE_EVAL_POPUP`,
  SHOW_RANK_POPUP: `${prefix}/SHOW_RANK_POPUP`,
  HIDE_RANK_POPUP: `${prefix}/HIDE_RANK_POPUP`
};

const initialState = {
  show: false, //start, stop
  showEndPopup: false,
  showEvalPopup: false,
  showRankPopup: undefined,
  evalPopupType: "",
  title: "",
  text: "",
  correctScore: undefined,
  totalScore: undefined,
  testJson: ''
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case PopupActions.SHOW_POPUP:
      const { title, text } = action.payload;
      return { ...state, show: true, title, text };
    case PopupActions.HIDE_POPUP:
      return { ...state, show: false };
    case PopupActions.SHOW_END_POPUP: {
      const { title, text, correctScore, totalScore, testJson = '' } = action.payload;
      return { ...state, showEndPopup: true, title, text, correctScore, totalScore, testJson };
    }
    case PopupActions.HIDE_END_POPUP:
      return { ...state, showEndPopup: false };
    case PopupActions.SHOW_BUG_POPUP:
      return { ...state, showBugPopup: true };
    case PopupActions.HIDE_BUG_POPUP:
      return { ...state, showBugPopup: false };
    case PopupActions.SHOW_EVAL_POPUP:
      const { evalPopupType } = action.payload;
      return { ...state, showEvalPopup: true, evalPopupType };
    case PopupActions.HIDE_EVAL_POPUP:
      return { ...state, showEvalPopup: false, evalPopupType: "" };
    case PopupActions.SHOW_RANK_POPUP:
      return { ...state, showRankPopup: true };
    case PopupActions.HIDE_RANK_POPUP:
      return { ...state, showRankPopup: false };
    default:
      return state;
  }
};

export function showPopup(payload) {
  return {
    type: PopupActions.SHOW_POPUP,
    payload
  };
}

export function hidePopup() {
  return {
    type: PopupActions.HIDE_POPUP
  };
}

export function showEndPopup(payload) {
  return {
    type: PopupActions.SHOW_END_POPUP,
    payload
  };
}

export function hideEndPopup() {
  return {
    type: PopupActions.HIDE_END_POPUP
  };
}

export function showBugPopup() {
  return {
    type: PopupActions.SHOW_BUG_POPUP
  };
}

export function hideBugPopup() {
  return {
    type: PopupActions.HIDE_BUG_POPUP
  };
}

export function showEvalPopup(payload) {
  return {
    type: PopupActions.SHOW_EVAL_POPUP,
    payload
  };
}

export function hideEvalPopup() {
  return {
    type: PopupActions.HIDE_EVAL_POPUP
  };
}

export function showRankPopup() {
  return {
    type: PopupActions.SHOW_RANK_POPUP
  };
}

export function hideRankPopup() {
  return {
    type: PopupActions.HIDE_RANK_POPUP
  };
}

export default popupReducer;
