import React, {Suspense, lazy} from "react";
import Loading from "src/components/Loading";
import {Route, Switch} from "react-router-dom";
import NewClassPage from "./Class/new_index";

const SigninPage = lazy(() => import("src/pages/Signin"));
const WhaleSigninPage = lazy(() => import("src/pages/Signin/Whale"));
const HomePage = lazy(() => import("src/pages/Home"));
const RankingPage = lazy(() => import("src/pages/Ranking"));
const MyPage = lazy(() => import("src/pages/My"));
const ClassPage = lazy(() => import("src/pages/Class"));
const WordAudioModule = lazy(() => import("src/pages/Module/Audio/Word"));
const SentenceAudioModule = lazy(() => import("src/pages/Module/Audio/Sentence"));
const PatternAudioModule = lazy(() => import("src/pages/Module/Audio/Pattern"));
const ReadingAudioModule = lazy(() => import("src/pages/Module/Audio/Reading"));
const WordTypingModule = lazy(() => import("src/pages/Module/Typing/Word"));
const SentenceTypingModule = lazy(() => import("src/pages/Module/Typing/Sentence"));
const VideoModule = lazy(() => import("src/pages/Module/Video"));
const QuizModule = lazy(() => import("src/pages/Module/Quiz"));
const Test = lazy(() => import("src/pages/Test/index"));

function App() {
    return (
        <div className="App">
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route exact path="/" component={SigninPage} />
                    <Route exact path="/whale" component={WhaleSigninPage} />
                    <Route path="/home" component={HomePage} />
                    <Route path="/ranking" component={RankingPage} />
                    <Route path="/my" component={MyPage} />
                    <Route path="/book/:bookId/class" component={ClassPage} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/audio/word" component={WordAudioModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/audio/sentence" component={SentenceAudioModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/audio/pattern" component={PatternAudioModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/audio/reading" component={ReadingAudioModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/typing/word" component={WordTypingModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/typing/sentence" component={SentenceTypingModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/quiz" component={QuizModule} />
                    <Route path="/book/:bookId/learning/:learningId/module/:moduleId/media" component={VideoModule} />
                    <Route path="/test" component={Test} />
                    <Route path="/newClass" component={NewClassPage} />
                </Switch>
            </Suspense>
        </div>
    );
}

export default App;
