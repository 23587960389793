import { getFetcherWithToken } from "src/utils/Fetcher";

// sample
import sampleMp3 from "src/assets/audio/sample.mp3";
import dollMp3 from "src/assets/audio/doll.mp3";
import beforeMp3 from "src/assets/audio/before.mp3";
import sampleImg from "src/assets/images/common/alone_sample.jpg";
import dollImg from "src/assets/images/common/sample_doll.jpg";
import beforeImg from "src/assets/images/common/sample_before.jpg";
import { getMediaUri } from "src/utils/media";

const questionsMock = [
  { audio: sampleMp3, eng: "alone", kor: "혼자, 외로운", image: sampleImg },
  { audio: dollMp3, eng: "doll", kor: "인형", image: dollImg },
  { audio: beforeMp3, eng: "before", kor: "전에, 하기 전에", image: beforeImg }
];

function getAudioQuestions(payload) {
  return token =>
    getFetcherWithToken(token)
      .get(`/question?module_id=${payload.moduleId}`)
      .then(res => {
        return {
          learningModule: res.data.ss_learning_module,
          questions: res.data.ss_questions.map(item => {
            return {
              ...item,
              audio: new Audio(getMediaUri(item.audio.path)),
              image: item.image ? getMediaUri(item.image.path) : ""
            };
          })
        };
      });
}

export default {
  getAudioQuestions
};
