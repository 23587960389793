import { combineReducers } from "redux";
import audio from "./audio/audio.ducks";
import typing from "./typing/typing.ducks";
import video from "./video/video.ducks";
import learning from "./learning/learning.ducks";
import timer from "./timer/timer.ducks";
import popup from "./popup/popup.ducks";
import session from "./session/session.ducks";
import event from "./event/event.ducks";
import quiz from "./quiz/quiz.ducks";
import ranking from "./ranking/ranking.ducks";

export default combineReducers({
  audio,
  typing,
  video,
  learning,
  timer,
  popup,
  session,
  event,
  quiz,
  ranking,
});
