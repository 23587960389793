import React, {useState, useMemo, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Swiper, Navigation, Pagination, Scrollbar, Autoplay} from "swiper";
import Anime from "react-anime";
import {formatDistanceStrict} from "date-fns";
import ko from "date-fns/locale/ko";
import {getBookLearnings, getBooks, clearLearnings} from "src/stores/learning/learning.ducks";
import {getEventHistories} from "src/stores/event/event.ducks";
import {getLearningModuleUrl} from "src/utils/urls";
import {getDate, getDateFormatOrEmpty} from "src/utils/Date";
import {homeBackground} from "src/utils/audio";
import HomeBase from "src/components/HomeBase";
import {isMobile} from "react-device-detect";
import AlertPopup from "src/components/Module/alertPopup";
import giftCardImg from "src/assets/images/common/gift_card.png";
import userIcon from "src/assets/images/common/icon_user_event.svg";
import "src/assets/css/common.css";
import "src/assets/css/myClass/myClass.css";

import "src/assets/css/base.css";
import "src/assets/css/mypage.css";
import "src/assets/css/swiper.min.css";

const NewClassPage = ({match, history}) => {
    const {bookId} = match.params;
    console.log(bookId);
    const dispatch = useDispatch();
    const {books, learnings, user, event, histories, mode} = useSelector((state) => ({
        books: state.learning.books,
        learnings: state.learning.learnings,
        user: state.session.user,
        event: state.event.event,
        histories: state.event.histories,
        mode: state.learning.classMode,
    }));

    const [eventSlider, setEventSlider] = useState();
    const [classSlider, setClassSlider] = useState();
    const book = useMemo(() => {
        if (books.length > 0) {
            return books.filter((item) => item.book.id === parseInt(bookId))[0];
        } else {
            return {};
        }
    }, [books, bookId]);

    const myClassStyle = {
        titleText: {
            fontSize: "24px",
            fontWeight: "normal",
            fontFamily: "noto_B",
            paddingBottom: "2rem",
        },
    };

    useEffect(() => {
        Swiper.use([Navigation, Pagination, Scrollbar, Autoplay]);
        new Swiper(".event_slider_container", {
            direction: "vertical",
            slidesPerView: 3,
            spaceBetween: 10,
            loop: true,
            autoplay: {delay: 3000},
        });
    }, []);

    return (
        <>
            <HomeBase>
                <div className="myClass_wrapper">
                    <div className="myClass_col">
                        <div className="myClass_box">
                            <h1 style={myClassStyle.titleText} className="myClass_box_title">
                                학습정보
                            </h1>
                            <div className="book_info_wrapper">
                                <p className="book_img"></p>
                                <div className="book_info">
                                    <div className="info_input_layout">
                                        <div className="input_title_row">
                                            <span>교재명</span>
                                        </div>
                                        <div className="common_input_box_layout">투스파닉스 vol.2</div>
                                    </div>
                                    <div className="info_input_layout">
                                        <div className="input_title_row">
                                            <span>학습기간</span>
                                            <h2>
                                                학습종료까지 <span>13일</span> 남았습니다.
                                            </h2>
                                        </div>
                                        <div className="common_input_box_layout">2022-03-03 ~ 2022-03-04</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="myClass_box">
                            <div className="myClass_box_title_row">
                                <h1 style={myClassStyle.titleText} className="myClass_box_title">
                                    이벤트 당첨자 목록
                                </h1>
                                <span>2022-06</span>
                            </div>
                            <div className="event_slider_row">
                                <div className="event_product">
                                    <img src="https://cdn.picpng.com/box/present-box-dole-favor-gift-93695.png" alt="" />
                                </div>
                                <div style={{height: "300px"}} className="event_slider_container">
                                    <div style={{height: "300px"}} className="swiper-wrapper">
                                        <div style={{height: "50px", fontSize: "14px"}} className="swiper-slide">
                                            <div>
                                                <img src={userIcon} alt="" />
                                                <h1>김초롱</h1>
                                            </div>
                                            <h2>파머스어학원 죽전본원</h2>
                                            <h3>2022-03-04</h3>
                                        </div>
                                        <div style={{height: "50px", fontSize: "14px"}} className="swiper-slide">
                                            <div>
                                                <img src={userIcon} alt="" />
                                                <h1>김초롱</h1>
                                            </div>
                                            <h2>파머스어학원 죽전본원</h2>
                                            <h3>2022-03-04</h3>
                                        </div>
                                        <div style={{height: "50px", fontSize: "14px"}} className="swiper-slide">
                                            <div>
                                                <img src={userIcon} alt="" />
                                                <h1>김초롱</h1>
                                            </div>
                                            <h2>파머스어학원 죽전본원</h2>
                                            <h3>2022-03-04</h3>
                                        </div>
                                        <div style={{height: "50px", fontSize: "14px"}} className="swiper-slide">
                                            <div>
                                                <img src={userIcon} alt="" />
                                                <h1>김초롱</h1>
                                            </div>
                                            <h2>파머스어학원 죽전본원</h2>
                                            <h3>2022-03-04</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="myClass_col">
                        <div className="myClass_box">
                            <h1 style={myClassStyle.titleText} className="myClass_box_title">
                                학습단계
                            </h1>
                            <ul className="myClass_unit_list">
                                <li>
                                    <h1>Day 1</h1>
                                    <div className="progress_bar">
                                        <span></span>
                                        <p>100%</p>
                                    </div>
                                    <span className="mark"></span>
                                </li>
                                <li>
                                    <h1>Day 1</h1>
                                    <div className="progress_bar">
                                        <span></span>
                                        <p>100%</p>
                                    </div>
                                    <span className="mark"></span>
                                </li>
                                <li>
                                    <h1>Day 1</h1>
                                    <div className="progress_bar">
                                        <span></span>
                                        <p>100%</p>
                                    </div>
                                    <span className="mark"></span>
                                </li>
                                <li>
                                    <h1>Day 1</h1>
                                    <div className="progress_bar">
                                        <span></span>
                                        <p>100%</p>
                                    </div>
                                    <span className="mark"></span>
                                </li>
                                <li>
                                    <h1>Day 1</h1>
                                    <div className="progress_bar">
                                        <span></span>
                                        <p>100%</p>
                                    </div>
                                    <span className="mark"></span>
                                </li>
                                <li>
                                    <h1>Day 1</h1>
                                    <div className="progress_bar">
                                        <span></span>
                                        <p>100%</p>
                                    </div>
                                    <span className="mark"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </HomeBase>
        </>
    );
};
export default NewClassPage;
